import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import bgImage from "Assets/bg.png";

const Menu = styled.header``;

const MenuHeader = styled.div`
    background: #e3c9af;
    border-bottom: 1px solid #27303c;
    display: flex;
    font-weight: 600;
    font-size: 1rem;
    padding: 1rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
`;

const MenuMenu = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
`;

const MenuItemsDiv = styled.div`
    background-image: url("${bgImage}");
    background-size: 100% auto;
    color: white;
    position: fixed;
    top: 0;
    font-size: 1.25rem;
    left: ${(props) => (props.menuOpen ? "0vw" : "-90vw")};
    width: 90vw;
    height: 100%;
    padding: 1rem;
    transition: all 0.2s ease;
    ${MenuMenu} {
        margin-bottom: 1rem;
    }
`;

const StyledNavLink = styled(NavLink)`
    display: block;
    margin-bottom: 0.5rem;
`;

const MenuItemsList = styled.nav`
    display: flex;
    flex-direction: column;
    padding: 1rem 0.333rem;
`;

const MenuIcon = ({ fill }) => (
    <svg viewBox="0 0 100 80" width="30" height="30">
        <rect fill={fill} width="80" height="15"></rect>
        <rect fill={fill} y="30" width="80" height="15"></rect>
        <rect fill={fill} y="60" width="80" height="15"></rect>
    </svg>
);

export default ({ menuItems }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <Menu>
            <MenuHeader>
                <MenuMenu onClick={() => setMenuOpen(true)}>
                    <MenuIcon fill="#27303c" />
                </MenuMenu>
                <Link to="/">
                    <span style={{ fontSize: "1.25rem" }}>
                        Dr Alison Ravenscroft
                    </span>
                    <br />
                    Psychotherapy &amp; Psychoanalysis
                </Link>
            </MenuHeader>
            <div style={{ padding: "1rem 0" }}>&nbsp;</div>
            <MenuItemsDiv menuOpen={menuOpen}>
                <MenuMenu onClick={() => setMenuOpen(false)}>
                    <MenuIcon fill="white" />
                </MenuMenu>
                <MenuItemsList>
                    {menuItems.map((item) => (
                        <StyledNavLink
                            key={item.url}
                            exact
                            activeClassName="active"
                            to={item.url}
                            onClick={() => setMenuOpen(false)}
                        >
                            {item.title}
                        </StyledNavLink>
                    ))}
                </MenuItemsList>
            </MenuItemsDiv>
        </Menu>
    );
};
