import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Contact, FirebaseContext, PageView, Columns, Grid } from "Components";

export default () => {
    const { flamelink } = useContext(FirebaseContext);
    const { slug } = useParams();

    const [content, setContent] = useState({});

    useEffect(() => {
        async function fetchData() {
            const data = await flamelink.content.getByField({
                schemaKey: "page",
                field: "url",
                value: `/${slug || ""}`,
            });
            setContent(Object.values(data)[0]);
        }
        fetchData();
    }, [slug, flamelink]);

    if (!content) return null;

    return (
        <PageView>
            <h1>{content.title}</h1>
            {content.type === "two-columns" && <Columns content={content} />}
            {content.type === "grid" && <Grid items={content.gridItems} />}
            {content.type === "Contact Page" && (
                <Contact content={content.contentInFrayedBox} />
            )}
        </PageView>
    );
};
