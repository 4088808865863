import React from "react";
import styled from "styled-components";

import { BrowserRouter } from "react-router-dom";
import FirebaseAnalytics from "Components/Firebase/FirebaseAnalytics";
import { GlobalStyle, Menu, Router as SiteRouter } from "Components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;

    @media screen and (max-width: 850px) {
        flex-direction: column;
    }
`;

const MainSection = styled.div`
    flex: 1 1 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 5rem;
    @media screen and (max-width: 850px) {
        padding: 0 1rem 5rem;
    }
`;

function App() {
    return (
        <BrowserRouter>
            <GlobalStyle />
            <FirebaseAnalytics />
            <Container>
                <Menu />
                <MainSection>
                    <SiteRouter />
                </MainSection>
            </Container>
        </BrowserRouter>
    );
}

export default App;
