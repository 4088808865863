import { createGlobalStyle } from "styled-components";

import standardBook from "Assets/fonts/standard-book-webfont.woff";
import standardBook2 from "Assets/fonts/standard-book-webfont.woff2";
import standardBookItalic from "Assets/fonts/standard-book-italic-webfont.woff";
import standardBookItalic2 from "Assets/fonts/standard-book-italic-webfont.woff2";

import standardBold from "Assets/fonts/standard-bold-webfont.woff";
import standardBold2 from "Assets/fonts/standard-bold-webfont.woff2";
import standardBoldItalic from "Assets/fonts/standard-bold-italic-webfont.woff";
import standardBoldItalic2 from "Assets/fonts/standard-bold-italic-webfont.woff2";

export default createGlobalStyle`

    @font-face {
    font-family: 'Standard';
    font-style:  normal;
    font-weight: normal;
    src: url(${standardBook2}) format("woff2"),
        url(${standardBook}) format("woff");
    }

    @font-face {
    font-family: 'Standard';
    font-style:  italic;
    font-weight: normal;
    src: url(${standardBookItalic2}) format("woff2"),
        url(${standardBookItalic}) format("woff");
    }

    @font-face {
    font-family: 'Standard';
    font-style:  normal;
    font-weight: bold;
    src: url(${standardBold2}) format("woff2"),
        url(${standardBold}) format("woff");
    }

    @font-face {
    font-family: 'Standard';
    font-style:  italic;
    font-weight: bold;
    src: url(${standardBoldItalic2}) format("woff2"),
        url(${standardBoldItalic}) format("woff");
    }

    * { 
        box-sizing: border-box;
    }

    html {
        font-size: 106.25%;
        font-family: Standard;
        line-height: 1.4;
        background: #E3C9AF;
        color: #273037;

        @media screen and (min-width: 1440px){
            font-size: 120%;
        }

        @media screen and (min-width: 1920px){
            font-size: 130%;
        }
    }

    a { 
        color: inherit;
        text-decoration: none;
    }

    html, body {
        margin: 0;
    }

    h1 {
        font-size: 1.176rem;
        line-height: 1.4;
        margin: 32px 0;
        @media (max-width: 850px) {
            font-size: 1.75rem;
        }
    }

    p {
        margin: 0 0 0.75rem;
    }

    p:empty {
        margin: 0 !important;
    }

    hr {
        height: 1px;
        width: 100%;
        background: rgba(0,0,0,0.75);
        border: 0;
        margin: 4rem 0;
    }
`;
