import React from "react";
import { Switch, Route } from "react-router-dom";

import { Page } from "Components";

export default function App() {
    return (
        <Switch>
            <Route exact path="/">
                <Page isHome={true} />
            </Route>
            <Route exact path="/:slug">
                <Page />
            </Route>
        </Switch>
    );
}
