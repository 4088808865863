import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { FirebaseContext } from "Components";
import Box1 from "Assets/Box1.png";
import Box2 from "Assets/Box2.png";
import Box3 from "Assets/Box3.png";
import Box4 from "Assets/Box4.png";

const GridView = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const GridItem = styled.a`
    align-items: flex-start;
    background-image: url("${Box1}");
    background-position: center;
    background-size: 100% 100%;
    justify-content: center;
    color: white;
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 1rem);
    height: 15rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 3rem;

    @media screen and (max-width:850px) {
        flex-basis: 100%; 
        margin-right: 0;
    }
    
    &:nth-child(4n - 3) {
        background-image: url("${Box1}");
    }
    &:nth-child(4n - 2) {
        background-image: url("${Box2}");
    }
    &:nth-child(4n - 1) {
        background-image: url("${Box3}");
    }
    &:nth-child(4n) {
        background-image: url("${Box4}");
    }

    &:nth-child(2n) {
        margin-right: 0;
    }
    &:hover {
        opacity: 0.9;
    }
`;

const Title = styled.div`
    font-size: 1.176rem;
    margin-bottom: 0.2rem;
`;
const Subtitle = styled.div`
    font-size: 0.823529rem;
    line-height: 1.5;
`;

const Item = ({ title, subtitle, pdf, link }) => {
    const { flamelink } = useContext(FirebaseContext);
    const [pdfUrl, setPdfUrl] = useState("");
    useEffect(() => {
        async function fetchData() {
            const url = await flamelink.storage.getURL({
                fileId: pdf[0].id,
            });
            setPdfUrl(url);
        }
        fetchData();
    }, [pdf, flamelink]);
    return (
        <GridItem key={title} href={pdfUrl || link || ""} target="_blank">
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            <div style={{ marginTop: "1rem", fontSize: "0.823529rem" }}>
                Read here »
            </div>
        </GridItem>
    );
};
export default ({ items }) => {
    return items ? (
        <GridView>
            {items.map((item) => (
                <Item key={item.title} {...item} />
            ))}
        </GridView>
    ) : null;
};
