import React from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import bgImage from "Assets/bg.png";

const Menu = styled.header`
    background-image: url("${bgImage}");
    background-size: 100% auto;
    flex: 0 0 340px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: white;

    @media screen and (min-width: 1024px){
        flex-basis: 320px;
    }

    @media screen and (min-width: 1280px){
        flex-basis: 340px;
    }

    @media screen and (min-width: 1440px){
        flex-basis: 400px;
    }

    @media screen and (min-width: 1920px){
        flex-basis: 450px;
    }
`;

const MenuItemsDiv = styled.nav`
    display: flex;
    flex-direction: column;
    a {
        display: block;
        margin-bottom: 8px;
    }
    a.active {
        font-weight: 600;
    }
`;

const MenuHeader = styled.div`
    margin-bottom: 3rem;
    font-size: 1.176rem;
    font-weight: 600;
    @media (min-height: 790px) {
        margin-bottom: 5rem;
    }
`;

const MenuContent = styled.div`
    position: fixed;
`;

export default ({ menuItems }) => (
    <Menu>
        <MenuContent>
            <MenuHeader>
                <Link to="/">
                    Dr Alison Ravenscroft
                    <br />
                    Psychotherapy &amp;
                    <br />
                    Psychoanalysis
                </Link>
            </MenuHeader>
            <MenuItemsDiv>
                {menuItems.map((item) => (
                    <NavLink
                        key={item.url}
                        exact
                        activeClassName="active"
                        to={item.url}
                    >
                        {item.title}
                    </NavLink>
                ))}
            </MenuItemsDiv>
        </MenuContent>
    </Menu>
);
