import React, { useContext, useEffect, useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";

import { FirebaseContext } from "Components";

import MenuDesktop from "./_MenuDesktop";
import MenuMobile from "./_MenuMobile";

export default () => {
    const { flamelink } = useContext(FirebaseContext);
    const [menuItems, setMenuItems] = useState([]);
    const windowWidth = useWindowWidth();
    useEffect(() => {
        async function fetchData() {
            const menu = await flamelink.nav.get({ navigationKey: "home" });
            setMenuItems(menu.items);
        }
        fetchData();
    }, [flamelink]);

    return windowWidth > 850 ? (
        <MenuDesktop menuItems={menuItems} />
    ) : (
        <MenuMobile menuItems={menuItems} />
    );
};
