import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown/with-html";

const ColumnsView = styled.div`
    display: flex;
    > div {
        &:first-child {
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 3rem;
        }
        &:last-child {
            margin-right: 0;
        }
        img {
            width: 100%;
            height: auto;
            margin-bottom: 1rem;
        }
        a {
            text-decoration: underline;
            text-decoration-color: rgba(0, 0, 0, 0.25);
        }
    }
    attr {
        font-size: 0.8rem;
        font-weight: 600;
        margin-left: 0.7rem;
    }

    @media screen and (max-width: 850px) {
        flex-direction: column;
        > div:first-child {
            margin-right: 0;
            margin-bottom: 2rem;
        }
    }
`;

export default ({ content }) => (
    <ColumnsView>
        <div style={{ flexBasis: `${content.firstColumnSize}%` }}>
            <ReactMarkdown
                source={content.twoColumnContent.columnOne}
                escapeHtml={false}
            />
        </div>

        <div>
            <ReactMarkdown
                source={content.twoColumnContent.columnTwo}
                escapeHtml={false}
            />
        </div>
    </ColumnsView>
);
