import React from "react";
import styled from "styled-components";

import box from "Assets/ContactBox.png";
import fragment from "Assets/fragment.png";

const ContactBox = styled.div`
    align-items: center;
    background-image: url("${box}");
    background-position: center;
    background-size: 100% 100%;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 1.1764705882rem;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    max-width: 605px;
    min-height: 374px;
    padding: 2rem;
    width: 100%;

    @media screen and (min-width: 1600px) {
        max-width: 700px;
    }

    > div {
        max-width: 454px;
    }

    dl {
        display: flex;
        margin: 0 0 0.75rem; 
        padding: 0;
        dt { flex: 0 0 2rem; margin:0;padding:0; }
        dd { flex: 1; margin: 0; padding: 0;}
    }
`;

const ContactImg = styled.img`
    max-height: 374px;
    width: auto;
    margin-left: 3rem;
    @media screen and (max-width: 1279px) {
        display: none;
    }
`;

const ContactWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

export default ({ content }) => {
    return (
        <ContactWrapper>
            <ContactBox>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </ContactBox>
            <ContactImg src={fragment} />
        </ContactWrapper>
    );
};
