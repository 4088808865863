import styled from "styled-components";

export default styled.div`
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;

    > h1 {
        margin-bottom: 5.1rem;
        @media (min-height: 790px) {
            margin-bottom: 8.1rem;
        }
        @media (max-width: 850px) {
            margin-top: 4rem;
            margin-bottom: 2rem;
        }
    }

    @media screen and (min-width: 1280px) {
        max-width: 960px;
    }

    @media screen and (min-width: 1440px) {
        max-width: 1024px;
    }

    @media screen and (min-width: 1920px) {
        max-width: 1280px;
    }
`;
