import * as app from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import flamelink from "flamelink/app";
import "flamelink/content";
import "flamelink/storage";
import "flamelink/navigation";

const firebaseConfig = {
    apiKey: "AIzaSyACTX6oiaR3sQH1vM12PtbjHQbadgHRb20",
    authDomain: "psychoanalysis-melbourne.firebaseapp.com",
    databaseURL: "https://psychoanalysis-melbourne.firebaseio.com",
    projectId: "psychoanalysis-melbourne",
    storageBucket: "psychoanalysis-melbourne.appspot.com",
    messagingSenderId: "448936719170",
    appId: "1:448936719170:web:31b45f302bc39b97104edb",
    measurementId: "G-K7KNK1QCSC",
};

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);
        this.firestore = app.firestore();
        this.firebase = app;
        app.analytics();
        this.flamelink = flamelink({ firebaseApp: app, dbType: "cf" });
    }
}
export default Firebase;
