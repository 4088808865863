import { useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import { FirebaseContext } from "Components";

const FirebaseAnalytics = () => {
    let location = useLocation();

    const { firebase } = useContext(FirebaseContext);

    useEffect(() => {
        const analytics = firebase && firebase.analytics;
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location, firebase]);
    return null;
};

export default FirebaseAnalytics;
